// the following must be global functions in order to be called by code in pollyfills.ts

export function getSavedLanguageCode() {
    let languageCode = getSavedLocaleCode();
    if (languageCode.length > 2)
        languageCode = languageCode.substring(0, 2);
    return languageCode;
}

const savedLocaleKey = 'mp_locale';

export function getSavedLocaleCode() {
    return localStorage.getItem(savedLocaleKey) ?? '';
}

export function setSavedLocaleCode(localeCode: string) {
    localStorage.setItem(savedLocaleKey, localeCode);
}

export function deleteSavedLocaleCode() {
    localStorage.removeItem(savedLocaleKey);
}
